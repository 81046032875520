/**
 * Form Item 组件
 */

const requireComponent = require.context('./', false, /\w+\.vue$/) // 遍历当前目录

const comps = {}

requireComponent.keys().map(fileName => {
  const comp = requireComponent(fileName).default
  comps[comp.name] = comp
})

export default comps

