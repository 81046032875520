<template slot="pic">
  <el-image v-if="src !== ''" fit="contain" style="width: 50px; height: 50px" :src="src" :preview-src-list="srcList">
    <div slot="error" />
  </el-image>
</template>
<script>
export default {
  name: 'CustomPic',
  props: {
    src: {
      type: String,
      default: ''
    },
    srcList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
