<template>
  <el-switch v-model="status" :disabled="disabled" :active-value="1" :inactive-value="0" active-color="#13ce66" @change="changeStatus($event)" />
</template>
<script>

export default {
  name: 'CustomStatus',
  props: {
    value: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      status: this.value
    }
  },
  watch: {
    value() {
      if (this.status !== this.value) {
        this.status = this.value
      }
    }
  },
  methods: {
    setStatus(status) {
      this.status = status
    },
    changeStatus($event) {
      this.$emit('change', $event, this)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
