<template>
  <el-cascader
    ref="tenant"
    v-model="tenant"
    :disabled="disabled"
    :options="regionList"
    :props="regionProps"
    :placeholder="placeholder"
    :clearable="clearable"
    style="width: 100%;"
    @change="change"
  />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RegionForm',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tenant: this.value,
      regionList: [],
      disableValues: [],
      regionDefaultData: [],
      regionProps: {
        value: 'id',
        label: 'regionName',
        lazy: true,
        checkStrictly: true,
        multiple: this.multiple,
        lazyLoad: this.getRegionList
      }
    }
  },
  watch: {
    value(newValue) {
      this.tenant = newValue
    }
  },
  mounted() {
    this.$emit('input', this.tenant)
  },
  created() {
    this.disableValues = this.disableValue.split(',')
    if (this.disableValues.length > 2) {
      this.disableValues = this.disableValues.slice(2)
    } else {
      this.disableValues = []
    }
  },
  methods: {
    ...mapActions([
      'getRegionServe'
    ]),
    getRegionList(node, resolve) {
      const { level } = node
      let pid = ''
      if (this.disableValues.length > 0 && level === 0) {
        pid = this.disableValues[this.disableValues.length - 1]
      } else {
        pid = level === 0 ? 101 : node.value
      }
      this.getRegionServe({ pid }).then((res) => {
        if (res.code === 200) {
          let data
          if (level >= 4) {
            data = res.data.map(item => {
              item['id'] = item['id'].toString()
              item['leaf'] = true
              return item
            })
          } else {
            node.children = []
            data = res.data.map(item => {
              item['id'] = item['id'].toString()
              item['leaf'] = this.disableValues.length > 0 ? this.disableValues.length === 4 - level : level === 4
              return item
            })
          }
          resolve(data)
        }
      })
    },
    // 初始化区域
    async initRegion() {
      let tenant = this.$tools.deepClone(this.tenant)
      if (!this.multiple) {
        tenant = [tenant]
      }

      const pids = []
      const result = []
      for (const item of tenant) {
        item.unshift(101)
        for (let index = item.length - 2; index >= 0; index--) {
          if (pids.indexOf(item[index]) === -1) {
            result.push(await this.getRegionNode(item[index]))
            pids.push(item[index])
          }
        }
      }

      const flatArr = result.reduce((acc, cur) => acc.concat(cur), [])

      this.regionList = this.buildTree(flatArr)
    },
    // 组装Tree结构
    buildTree(data) {
      const map = {}
      const roots = []

      data.forEach((node) => {
        map[node.id] = {
          ...node,
          children: []
        }

        if (node.pid === 101) {
          roots.push(map[node.id])
        }
      })

      data.forEach((node) => {
        const parent = map[node.pid]
        if (parent) {
          parent.children.push(map[node.id])
        }
      })

      return roots
    },
    // 获取子节点
    async getRegionNode(pid) {
      let retData = []
      await this.getRegionServe({ pid }).then((res) => {
        if (res.code === 200) {
          retData = res.data.map(item => {
            item['id'] = item['id'].toString()
            return item
          })
        }
      })
      return retData
    },
    change(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.el-cascader-menu__wrap {
  height: 500px !important;
  /* 这里设置合适的高度值 */
  overflow-y: auto;
  /* 如果内容高度超过设定高度，将会显示滚动条 */
}
</style>
